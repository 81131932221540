<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <span>An initiative of</span>
      <b-link
        class="text-pantone-445"
        href="https://www.e2i.com.sg"
        target="_blank"
        rel="nofollow"
      > Employment and Employability Institute</b-link>
      <span> © {{ new Date().getFullYear() }}. </span>

      <span>Powered by
        <b-link
          class="text-pantone-445"
          href="https://headhuntershq.com"
          target="_blank"
          rel="nofollow"
        >HeadHunters HQ</b-link>
      </span>

      <span class="d-sm-inline-block">&nbsp;|&nbsp;</span>
      <b-link
        class="text-pantone-445"
        href="https://www.e2i.com.sg/pdpa/"
        target="_blank"
        rel="nofollow"
      >Personal Data Protection Policy</b-link>
      <span class="d-sm-inline-block">,&nbsp;</span>
      <b-link
        class="text-pantone-445"
        href="https://www.e2i.com.sg/terms-of-use/"
        target="_blank"
        rel="nofollow"
      >Terms of Use</b-link>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
